@import "src/assest/sass/_variables.scss";

#selectedFiltersMain {
  display: flex;
  flex-wrap: wrap;

  .filterChip {
    padding: 3px 10px;
    border-radius: 20px;
    width: fit-content;
    align-items: center;
    background-color: $smokeWhite_2;
    border: 1px solid $smokeGray_6;
    margin-right: 5px;
    height: 27px;
    margin-top: 5px;

    label {
      color: $smokeGray_6;
      font-size: 13px;
      white-space: nowrap;
      line-height: 13px;
    }

    .removeFilterControl {
      color: $smokeGray_10;
      font-size: 15px;
      cursor: pointer;
      margin-left: 10px;
    }
  }
}
