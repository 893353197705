.accordian {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.customAaccordionHeader {
  padding-right: 0 !important;
  margin-bottom: 0 !important;
  :focus {
    border-color: transparent;
    box-shadow: none;
  }
  button {
    background-color: #eee !important;
    color: #404040 !important;
    font-weight: 500;
    font-size: 16px;
    padding: 10px 15px;
    
    &::after {
      background-image: var(--bs-accordion-btn-icon) !important;
    }
  }
}
