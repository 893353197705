#docuSignReportMain {
  .docuSignReportContainer {
    display: grid;
    grid-auto-rows: min-content;
    row-gap: 25px;
    padding: 15px 0.75rem;
  }
}

.docusignReportFilterEditorMain {
  display: grid;
  grid-template-rows: calc(95% - 2rem) 36px;
  row-gap: 10px;

  .editorControls {
    display: grid;
    grid-template-columns: 100px 100px;
    column-gap: 10px;
    margin-left: 50px;
    padding-top: 35px;
  }
}
