@import "src/assest/sass/_variables.scss";

.fileViewerMain {
  background-color: $white_1;
  border-radius: 5px;
  border: 1px dashed $smokeWhite;
  padding: 5px;
  display: grid;
  grid-template-columns: auto 85px;
  column-gap: 10px;
  align-items: center;
  height: 42px;

  &.fileNotFound {
    width: 65%;
    grid-template-columns: 100%;
  }

  label {
    color: $smokeGray_2;
    font-size: 15px;
    font-weight: 600;
    line-height: 15px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: break-all;
  }

  .fileViewerControls {
    display: grid;
    grid-template-columns: 40px 40px;
    column-gap: 5px;

    .fileViewerControl {
      width: 40px;
      height: 30px;
      background-color: $header_color;
      border: none;

      &:hover {
        color: $white_1;
      }
    }

    .disabledFileViewerControl {
      background-color: grey !important;
      border: 1px solid grey !important;
    }
  }
}

.fileViewerDialogMain {
  position: fixed;
  z-index: 99;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);

  .fileViewerWrapper {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .fileViewerDialogContent {
    margin: 0 auto;
    border-radius: 5px;
    overflow-y: auto;
    width: calc(100vw - 200px);
    height: calc(100vh - 100px);
  }

  .fileHasIssuesSection {
    background-color: $white_1;
    padding: 20px;
    margin: 0 auto;
    border-radius: 5px;
    height: 100%;
    width: 100%;
    display: grid;
    grid-auto-rows: min-content;
    row-gap: 30px;
    place-content: center;

    .fileIcon {
      font-size: 100px;
      margin: 0 auto;
      color: $smokeGray_10;
    }

    p {
      color: $smokeGray_10;
      font-size: 18px;
      letter-spacing: 0.4px;
    }
  }

  .imageViewerContainer {
    width: 100%;
    background-color: $white_1;
    padding: 20px 100px;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    p {
      color: $smokeGray_10;
      font-size: 15px;
      letter-spacing: 0.4px;
      text-align: center;
      line-height: 17px;
      margin-top: 20px;
    }

    .image {
      width: 80%;
      border-radius: 5px;
      border: 1px solid $smokeGray_2;
      object-fit: cover;
    }
  }

  .textFileViewerSection {
    width: 100%;
    height: 100%;
    background-color: $white_1;
    padding: 20px;
    border-radius: 5px;
  }

  .closeControl {
    position: absolute;
    top: 60px;
    right: 50px;
    width: 35px;
    height: 35px;
    background-color: $white_1;
    border: none;
    border-radius: 35px;
    font-size: 20px;
    color: $smokeGray_10;
  }
}
