#importExportMain {
  .formContent {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .selectBox {
    display: flex;
    width: 100%;
    align-items: baseline;
    justify-content: space-evenly;
  }
}
