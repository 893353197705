#radioControl {
  .radioControlContainer {
    display: flex;
    height: 1.5em;
    align-items: normal;
  }

  .inlineTable {
    display: inline-table;
  }
}
