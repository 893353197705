@import "src/assest/sass/_variables.scss";

#employeeFilesMain {
  .btnContainer {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 5px;
    flex-wrap: wrap;
  }

  .employeeFilesContainer {
    display: grid;
    grid-auto-rows: min-content;
    padding: 15px 0.75rem;
  }

  .profileAvatar {
    width: 3.5rem;
    height: 3.5rem;
    border-radius: 2rem;
  }

  .alertLabelNode {
    cursor: pointer;

    &:hover {
      color: $header_color;
    }

    .alertIcon {
      margin-top: 3px;
    }
  }

  .actionElements {
    white-space: nowrap;
  }

  .matrixContainer {
    display: flex;
  }

  .instructionBtn {
    display: flex;
    align-items: center;
    padding-left: 4px;
  }
}

.employeeFileFilterEditorMain {
  display: flex;
  flex-direction: column;
  row-gap: 10px;
}

.trainingContainer {
  border-bottom: dashed 1px #ccc;
  padding-bottom: 15px;
  margin-bottom: 10px;
}

.categoryName {
  font-size: 15px;
  font-weight: bold;
}

.rowContanier {
  margin-bottom: 25px;
}

#reqReportContainer {
  .yearDropdownContainer {
    width: 50%;
    margin-bottom: 10px;
  }

  .reqReportBtnContainer {
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
  }
}

@media (max-width: $break-sm) {
  #employeeFilesMain {
    .btnContainer {
      display: flex;
      flex-wrap: wrap;
    }

    .rowContanier {
      gap: 10px;
    }
  }
}
