@import "src/assest/sass/_variables.scss";

#dashboardInitialAndReCredMain {
  .dashboardInitialAndReCredContainer {
    display: grid;
    grid-auto-rows: min-content;
    padding: 15px 0.75rem;
  }

  .profileAvatar {
    width: 3.5rem;
    height: 3.5rem;
    border-radius: 2rem;
  }

  .alertLabelNode {
    cursor: pointer;

    &:hover {
      color: $header_color;
    }

    .alertIcon {
      margin-top: 3px;
    }
  }

  .actionElements {
    word-wrap: break-word;
    width: 190px;
    white-space: normal;
  }

  .instructionBtn {
    display: flex;
    align-items: center;
    padding-left: 4px;
  }

  .btnContainer {
    display: flex;
    align-items: baseline;
    justify-content: flex-end;
    gap: 10px;
    flex-wrap: wrap;
  }
}

.dashboardInitialAndReCredFilterEditorMain {
  display: flex;
  flex-direction: column;
  row-gap: 10px;
}

.sendReminderControl {
  width: 32px;
  height: 26px;
  outline: none;
  border-radius: 4px;
  font-size: 16px;
  color: $btn_orange_txt;
  background-color: $orng_btn_bg;
  border: solid 1px $btn_orrange;
}

@media (max-width: $break-sm) {
  #dashboardInitialAndReCredMain {
    .btnContainer {
      display: flex;
      flex-wrap: wrap;
    }

    .rowContanier {
      gap: 10px;
      justify-content: space-between;
    }
  }
}
