#psvMain {
  .mainContainer {
    display: flex;
    flex-direction: column;
  }

  .labelContainer {
    margin-bottom: 2px;
    margin-top: 10px;
  }

  .label {
    padding: 0;
    margin: 0;
    font-size: 15px;
    font-weight: 400;
  }

  .internalBox {
    color: #2e5014;
    background: #d5efc2;
    padding: 8px 8px 3px 30px;
    border-radius: 10px;
  }

  .btnsContainer {
    display: flex;
    gap: 5px;
  }

  .labelBox {
    margin-bottom: 2px;
    margin-top: 10px;
  }

  .selectWidth div:nth-child(2) {
    width: 23%;
    margin-left: 15px;
  }

  .noteContent {
    bottom: 12px;
    left: 18px;
    position: relative;
    font-size: 13px;
  }

  .boldNote {
    font-weight: bold;
  }
}
