#employeeEditorMain {
  .locationSiteControls {
    margin-bottom: -30px;
  }
  .saveBtn {
    margin-right: 8px;
  }
  .toggleWrapper {
    width: fit-content;
  }
  .imageUploaderMain {
    display: flex;
    align-self: baseline;
  }
  .empExclusionMain {
    display: flex;
    flex-direction: column;
    gap: 30px;
    overflow: auto;
  }
  .detailedTableMain {
    overflow: auto;
  }
  .notificationHeader {
    padding-right: 25px;
  }
}

.tableShowText {
  color: #404040;
  font-size: 16px;
  margin: 0 0 8px 0;
  padding: 10px 0;
}

td:has(.editIconContainer) {
  width: 150px;
}

.pronoun {
  font-size: 12px;
}

.canvas {
  padding-right: 20px;
}

.idContainer {
  font-size: 12px;
}
