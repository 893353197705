@import "src/assest/sass/_variables.scss";

.footerMain {
  padding: 1rem 7rem !important;
  display: grid;
  grid-template-columns: max-content max-content;
  justify-content: space-between;
  align-items: center;

  .logoContainer {
    display: grid;
    grid-auto-columns: auto;
    grid-auto-flow: column;
    column-gap: 30px;

    .logo {
      width: 78px;

      &.accmeLogo {
        width: 53px;
      }
    }
  }
}

@media (max-width: $break-sm) {
  .footerMain {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
}
