@import "src/assest/sass/_variables.scss";

#credentialingMain {
  .contentHeaderTitle {
    font-size: 1.5rem;

    span {
      font-size: 15px;
      color: $smokeGray;
    }
  }
}

.credentialUserProfileContainer {
  border-right: 1px solid;
  padding-right: 1rem;
  margin-right: 0.5rem;
  display: flex;
  align-items: center;

  .credentialUserNameSection {
    display: grid;
    grid-auto-rows: min-content;
    row-gap: 3px;
  }

  .userProfileAvatar {
    background-color: $orng_btn_bg;
    width: 3rem;
    height: 3rem;
    margin-right: 0.5rem;
    border-radius: 1.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    color: $white_1;
  }

  span {
    font-size: 1rem;
  }

  .profileImageContainer {
    height: 3rem;
    width: 3rem;
    margin-right: 0.5rem;

    .profileImage {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }
}

.requestReferenceControlContainer {
  margin-top: 35px;
}

.pronoun {
  font-size: 11px;
}