@import "src/assest/sass/_variables.scss";

#instructionsDialogMain {
  display: grid;
  grid-template-rows: min-content auto;
  border: none;
  overflow: hidden;
  z-index: 99999;

  .dialogHeader {
    background-color: $infoBox_bg;
    color: $header_color;
    border-radius: 0.5rem;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    display: grid;
    place-content: center;
    row-gap: 15px;
    padding: 10px 0;
    position: relative;
  }

  .infoIcon {
    width: 45px;
    height: 45px;
    margin: 0 auto;

    path {
      fill: $header_color;
    }
  }

  .instructionsHeading {
    text-transform: uppercase;
    font-weight: 700;
    text-align: center;
    font-size: 20px;
  }

  .closeControl {
    position: absolute;
    top: 10px;
    right: 10px;
    background-color: transparent;
    outline: none;
    border: none;
    width: 30px;
    height: 30px;

    .closeIcon {
      path {
        fill: $infoBox_bg_txt;
      }
    }
  }

  .dialogContent {
    padding: 20px 20px 20px 0px;
    max-height: 450px;
    overflow: auto;

    ul {
      margin-bottom: 0;

      li {
        font-size: 15px;
        margin-bottom: 5px;
      }
    }
  }
}
