@import "src/assest/sass/_variables.scss";

.checkboxSelectMain {
  display: flex;
  flex-direction: column;
  margin-top: 0.8rem;

  .requiredAsterisk {
    color: $error_red;
    font-size: 10px;
    margin-top: 3px;
    margin-right: 2px;
  }

  .errorMessage {
    color: $error_red;
    font-size: 12px;
    font-style: italic;
  }

  .label {
    font-size: 15px;
    margin-bottom: 3px;
    display: flex;
    align-items: flex-start;
  }

  .checkboxSelectControlsContainer {
    position: relative;
  }

  .checkboxSelectButton {
    width: 100%;
    border: solid 1px $smokeWhite;
    border-radius: 5px;
    padding: 8px 5px;
    display: flex;
    justify-content: space-between;
    background-color: $white_1;
    cursor: pointer;
    color: $smokeGray_10;

    .chevronIcon {
      color: $smokeGray_10;
      margin: 3px 10px 0 0;
    }

    .buttonLabel {
      cursor: pointer;
      text-align: left;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .optionsListContainer {
    position: absolute;
    z-index: 9;
    top: 40px;
    left: 0px;
    background-color: $white_1;
    width: 100%;
    box-shadow: 2px 1px 14px -2px $shadowTwo;
    max-height: 175px;
    overflow-y: auto;
    display: grid;
    grid-template-rows: min-content;
    border-radius: 5px;
    -ms-overflow-style: none;
    /* Internet Explorer 10+ */

    &::-webkit-scrollbar {
      display: none;
      /* Safari and Chrome */
    }

    .noResultFoundMessage {
      padding: 10px 20px;
      text-align: center;
      font-size: 16px;
    }

    .optionItem {
      padding: 8.6px 10px;
      cursor: pointer;
      display: flex;

      &:hover {
        background-color: $smokeWhite_2;
      }

      .optionItemLabel {
        line-height: initial;
        font-size: 15px;
      }
    }
  }
}
