.application-accordion {
  box-shadow: 1px 1px 3px $smokeWhite;
  border-radius: var(--bs-accordion-inner-border-radius);

  .accordion-header {
    .accordion-button {
      background-color: $accordion_header_button;
      padding: 10px 15px;
      font-size: 17px;
      font-weight: 600;
      letter-spacing: 0.4px;
      color: $smokeGray_12;

      &:focus {
        box-shadow: none;
      }

      &:not(.collapsed) {
        background-color: $accordion_header_button_expanded;

        &::after {
          background-image: var(--bs-accordion-btn-icon);
        }
      }
    }
  }

  .accordion-body {
    padding: 0;
  }
}