.layout-fixed {
  .main-sidebar {
    float: none;
    @include position(fixed, $top: 0, $right: 0, $bottom: 0, $left: 0);
  }

  .brand-link {
    @include size(250px, null);
  }
}

.main-sidebar {
  height: 100vh;
  overflow-y: hidden;
  z-index: 1038;
  overflow-x: hidden;
}

.sidebar nav {
  height: calc(100vh - 170px);
  overflow-y: auto;
  overflow-x: hidden !important;
}

.elevation-4 {
  box-shadow: 0 14px 28px $shadowOne, 0 10px 10px $shadowTwo !important;
}

.main-sidebar,
.main-sidebar::before {
  transition: margin-left 0.3s ease-in-out, width 0.3s ease-in-out;
  @include size(250px, null);
}

.sidebar-mini .main-sidebar .nav-link,
.sidebar-mini-md .main-sidebar .nav-link,
.sidebar-mini-xs .main-sidebar .nav-link {
  @include size(calc(250px - 1rem), null);
  transition: width 0.3s ease-in-out 0s;
}

.main-sidebar .brand-text,
.main-sidebar .logo-xl,
.main-sidebar .logo-xs,
.sidebar .nav-link p,
.sidebar .user-panel .info {
  transition: margin-left 0.3s linear, opacity 0.3s ease, visibility 0.3s ease;
}

.brand-link {
  display: block;
  font-size: 1.25rem;
  line-height: 1.5;
  padding: 0.8125rem 0.5rem;
  transition: width 0.3s ease-in-out;
  white-space: nowrap;
}

@supports not (-webkit-touch-callout: none) {
  .layout-fixed .wrapper .sidebar {
    height: calc(100vh - (3.5rem + 1px));
    float: left;
  }
}

.sidebar {
  height: calc(100% - (3.5rem + 1px));
  overflow-x: hidden;
  overflow-y: initial;
  padding-bottom: 0;
  padding-top: 0;
  scrollbar-color: $smokeGray_2 transparent;
  scrollbar-width: none;
}

.nav-sidebar {
  position: relative;
  @include size(250px, null);
  overflow: hidden;

  & > .nav-item {
    margin-bottom: 0;
    position: relative;

    input.menuVisible {
      position: absolute;
      left: 0;
      top: 0;
      z-index: 1;
      height: 40px;
      width: 100%;
      opacity: 0;
      cursor: pointer;
    }

    .nav-link {
      border-bottom: 2px solid $anchor_border;
    }
  }

  .nav-link {
    p {
      display: inline;
      margin: 0;
      white-space: normal;
      margin-left: 0.3rem;
      padding: 0 0 0 3.3rem;
    }
  }

  .nav-item {
    label {
      position: relative;
    }

    & > .nav-link {
      position: relative;
      margin-bottom: 0;
      font-size: 1.08rem;
    }
  }
}

ul.nav-treeview {
  .nav-link p {
    padding: 0 0 0 1rem;
  }

  .nav-item {
    border-bottom: 1px solid $border_navtree;
  }
}

[class*="sidebar-dark-"] {
  .nav-sidebar {
    & > .nav-item {
      & > .nav-link {
        &.active {
          color: $anchor_HoverTxtOractive;
          background-color: $link_active_bg;
          // box-shadow: 0 1px 3px rgb(0 0 0 / 12%), 0 1px 2px rgb(0 0 0 / 24%);
        }
      }
    }
  }
}

.nav-pills {
  .nav-link {
    @include border-radius(0px);
  }
}

.nav-sidebar .nav-link > .right,
.nav-sidebar .nav-link > p > .right {
  @include position(
    absolute,
    $top: 1rem,
    $right: 1rem,
    $bottom: null,
    $left: null
  );
}

.sidebar-collapse.sidebar-mini .main-sidebar .nav-sidebar .nav-link,
.sidebar-collapse.sidebar-mini-md .main-sidebar .nav-sidebar .nav-link,
.sidebar-collapse.sidebar-mini-xs .main-sidebar .nav-sidebar .nav-link {
  width: 4.6rem;
}

.sidebar-collapse .main-sidebar,
.sidebar-collapse .main-sidebar::before {
  margin-left: -250px;
}

#sidebar-overlay {
  background-color: $sideBar_Overlay;
  display: none;
  z-index: 1037;
  @include position(fixed, $top: 0, $right: 0, $bottom: 0, $left: 0);
}

body {
  &:not(.layout-fixed) {
    .main-sidebar {
      height: inherit;
      min-height: 100%;
      @include position(
        absolute,
        $top: 0,
        $right: null,
        $bottom: null,
        $left: null
      );
    }
  }
}

.sidebar-mini .main-sidebar .nav-link,
.sidebar-mini-md .main-sidebar .nav-link,
.sidebar-mini-xs .main-sidebar .nav-link {
  width: calc(250px - 0.5rem * 0);
  transition: width ease-in-out 0.3s;
}

.sidebar-collapse {
  .nav-sidebar {
    .nav-item {
      .nav-link {
        padding-left: 1.5rem !important;
        padding-right: 1.5rem !important;
      }
    }
  }
}

@media (max-width: 1279px) {
  //991.98
  .sidebar-open #sidebar-overlay {
    display: block;
  }
}

@media (min-width: 992px) {
  .sidebar-mini.sidebar-collapse
    .main-sidebar:not(.sidebar-no-expand).sidebar-focused,
  .sidebar-mini.sidebar-collapse .main-sidebar:not(.sidebar-no-expand):hover {
    width: 250px;
  }

  .sidebar-mini {
    &.sidebar-collapse {
      &.layout-fixed {
        .main-sidebar {
          &:hover {
            .brand-link {
              width: 250px;
            }
          }
        }

        .brand-link {
          width: 4.6rem;
        }
      }

      .main-sidebar {
        overflow-x: hidden;
      }

      .sidebar {
        .nav-sidebar {
          .nav-link {
            p {
              width: 0;
              white-space: nowrap;
            }
          }
        }
      }
    }
  }

  .sidebar-collapse.sidebar-mini
    .main-sidebar.sidebar-focused:not(.sidebar-no-expand)
    .nav-link,
  .sidebar-collapse.sidebar-mini
    .main-sidebar:hover:not(.sidebar-no-expand)
    .nav-link,
  .sidebar-collapse.sidebar-mini-md
    .main-sidebar.sidebar-focused:not(.sidebar-no-expand)
    .nav-link,
  .sidebar-collapse.sidebar-mini-md
    .main-sidebar:hover:not(.sidebar-no-expand)
    .nav-link,
  .sidebar-collapse.sidebar-mini-xs
    .main-sidebar.sidebar-focused:not(.sidebar-no-expand)
    .nav-link,
  .sidebar-collapse.sidebar-mini-xs
    .main-sidebar:hover:not(.sidebar-no-expand)
    .nav-link {
    width: calc(250px - 0.5rem * 0);
  }

  .sidebar-mini.sidebar-collapse
    .main-sidebar:not(.sidebar-no-expand).sidebar-focused
    .brand-text,
  .sidebar-mini.sidebar-collapse
    .main-sidebar:not(.sidebar-no-expand).sidebar-focused
    .logo-xl,
  .sidebar-mini.sidebar-collapse
    .main-sidebar:not(.sidebar-no-expand).sidebar-focused
    .sidebar
    .nav-sidebar
    .nav-link
    p,
  .sidebar-mini.sidebar-collapse
    .main-sidebar:not(.sidebar-no-expand).sidebar-focused
    .user-panel
    > .info,
  .sidebar-mini.sidebar-collapse
    .main-sidebar:not(.sidebar-no-expand):hover
    .brand-text,
  .sidebar-mini.sidebar-collapse
    .main-sidebar:not(.sidebar-no-expand):hover
    .logo-xl,
  .sidebar-mini.sidebar-collapse
    .main-sidebar:not(.sidebar-no-expand):hover
    .sidebar
    .nav-sidebar
    .nav-link
    p,
  .sidebar-mini.sidebar-collapse
    .main-sidebar:not(.sidebar-no-expand):hover
    .user-panel
    > .info {
    display: inline-block;
    margin-left: 0;
    -webkit-animation-name: fadeIn;
    animation-name: fadeIn;
    -webkit-animation-duration: 0.3s;
    animation-duration: 0.3s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    visibility: visible;
  }

  .sidebar-mini .nav-sidebar,
  .sidebar-mini .nav-sidebar .nav-link,
  .sidebar-mini .nav-sidebar > .nav-header {
    white-space: nowrap;
  }

  .sidebar-mini.sidebar-collapse .main-sidebar,
  .sidebar-mini.sidebar-collapse .main-sidebar::before {
    margin-left: 0;
    width: 4.6rem;
  }

  .sidebar-mini.sidebar-collapse .content-wrapper,
  .sidebar-mini.sidebar-collapse .main-footer,
  .sidebar-mini.sidebar-collapse .main-header {
    margin-left: 4.6rem !important;
  }

  .sidebar-mini.sidebar-collapse .brand-text,
  .sidebar-mini.sidebar-collapse .sidebar .nav-sidebar .nav-link p,
  .sidebar-mini.sidebar-collapse .sidebar .user-panel > .info {
    margin-left: -10px;
    -webkit-animation-name: fadeOut;
    animation-name: fadeOut;
    -webkit-animation-duration: 0.3s;
    animation-duration: 0.3s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    visibility: hidden;
  }
}

.nav-sidebar {
  .nav-treeview {
    display: none;
    list-style: none;
    padding: 0;

    li a {
      padding-left: 1.3rem;
      background-color: $tree_AnchorBg;
      color: $anchor_txt;

      &:hover {
        background-color: $anchor_bg;
      }
    }
  }
}

.nav-sidebar > .nav-item,
.nav-sidebar > .nav-item:focus {
  color: $smokeGray_4 !important;
  background-color: $anchor_bg !important;
}

.nav-sidebar li {
  span::before {
    @extend .sideBarNavIcon;
  }
}

.nav-sidebar .menu-is-opening > .nav-link i.right,
.nav-sidebar .menu-is-opening > .nav-link svg.right,
.nav-sidebar .menu-open > .nav-link i.right,
.nav-sidebar .menu-open > .nav-link svg.right {
  -webkit-transform: rotate(-90deg);
  transform: rotate(-90deg);
}

.nav-sidebar .nav-item > .nav-link .right {
  transition: -webkit-transform ease-in-out 0.3s;
  transition: transform ease-in-out 0.3s;
  transition: transform ease-in-out 0.3s, -webkit-transform ease-in-out 0.3s;
}

@media (min-width: 1025px) {
  body:not(.sidebar-mini-md):not(.sidebar-mini-xs):not(.layout-top-nav)
    .content-wrapper,
  body:not(.sidebar-mini-md):not(.sidebar-mini-xs):not(.layout-top-nav)
    .main-footer,
  body:not(.sidebar-mini-md):not(.sidebar-mini-xs):not(.layout-top-nav)
    .main-header {
    transition: margin-left 0.3s ease-in-out;
    margin-left: 250px;
  }
}

@media (max-width: 991.98px) {
  .sidebar-mini .sidebar-collapse .main-sidebar {
    box-shadow: none !important;
  }

  body:not(.sidebar-mini-md):not(.sidebar-mini-xs):not(.layout-top-nav)
    .content-wrapper,
  body:not(.sidebar-mini-md):not(.sidebar-mini-xs):not(.layout-top-nav)
    .main-footer,
  body:not(.sidebar-mini-md):not(.sidebar-mini-xs):not(.layout-top-nav)
    .main-header {
    transition: margin-left 0.3s ease-in-out;
  }
}

.brand-link {
  float: left;

  .brand-image {
    line-height: 0.8;
    margin: auto;
    display: block;
    margin-top: -3px;
    max-width: 100%;
  }
}

[class*="sidebar-dark-"] {
  background-color: $sideBar_bg;

  .sidebar {
    a {
      color: $anchor_txt;
      background-color: $anchor_bg;
      padding: 0.75rem 0.5rem;
    }
  }
}

[class*="sidebar-dark-"] .nav-sidebar > .nav-item.menu-open > .nav-link,
[class*="sidebar-dark-"] .nav-sidebar > .nav-item:hover > .nav-link {
  background-color: $anchor_HoverBg;
  color: $anchor_HoverTxtOractive;
}

.navbar-expand {
  .navbar-nav {
    .nav-link {
      &.hamburger_icon {
        padding-right: 0;
        padding-left: 0;

        &:hover,
        &:focus {
          color: $hamburgerIcon;
        }
      }
    }
  }
}

.sidebar {
  float: left;

  .os-content {
    padding: 0 !important;
  }
}

.top_header {
  margin: 0 1rem;
}

.nav-pills .nav-link {
  @include border-radius(0px !important);
}

/*----Custom side menu css----*/
.menuVisible ~ label ~ ul {
  height: 0;
  transition: height 0.5s;
  display: block !important;
}

.nav-item {
  &:hover {
    a {
      background: $link_active_bg;
      color: $anchor_HoverTxtOractive;
    }
  }
}

.nav-item.parentActive {
  a {
    background: $link_active_bg;
    color: $anchor_HoverTxtOractive;
  }

  .nav-item .nav-link.menuactive {
    background-color: $anchor_bg;
    color: $anchor_txt;
  }
}

.menuVisible:checked ~ label ~ ul {
  height: auto;
  transition: height 0.5s;
  overflow-y: auto;
}
