// Header Themes

$header_color : #393a3c;
$header_txt : #ffffff;
$header_border : #ffffff;
$header_link_txt : #404040;
$header_drp_down_bg : #f5f5f5;
$header_list_hover : #e5e5e5;
$bredcum_bg : #f5f6f8;
$header_reqst_btn : #4a4a4a;
$notification_Bg : #ffa500;
$notification_txt : #000000;

// InfoBox Themes

$infoBox_bg : #d5efc2;
$infoBox_icon : #92CD59;
$infoBox_bg_txt : #2e5014;

// Sidebar Themes

$sideBar_bg : #ffffff;
$anchor_bg : #f1f1f1;
$anchor_HoverBg : #393a3c;
$anchor_HoverTxtOractive : #ffffff;
$anchor_txt : #404040;
$anchor_border : #ffffff;
$hamburgerIcon : #ffffff;
$tree_AnchorBg : #ffffff;
$border_navtree : #f1f1f1;
$sideBar_Overlay : rgba(0, 0, 0, .1);
$link_active_bg : #393a3c;

// Tabel Themes

$table_thead : #0854ff;
$table_thead_txt : #ffffff;
$table_odd_tr_bg : #f5f5f5;
$table_txt_color : #333333;
$table_para_color : #333333;
$table_buttons : #ffffff;
$table_buttons_bg : #333333;
$table_hover_color : #dddddd;



// Box Shadow

$shadowOne : #00000040;
$shadowTwo : #00000038;

// Buttons Gradient Style One

$orng_btn_bg : #ffa014;
$gradientTwo : #ff7d41;
$btn_orange_hover : #ff7d00;
$btn_orange_txt : #ffffff;

// Buttons Gradient Style Two

$gray_btn_bg : #bccad3;
$btn_gray_hover : #e0e1e1;
$btn_gray_txt : #404040;
$btn_gray_border : #cccccc;


// input/select box border style

$inpt_select : #cccccc;


// toggle Button 

$toggle_buttonOne : #549446;
$toggle_buttonOneBg : #c4ddbf;
$toggle_buttonTwo : #dc3546;
$toggle_buttonTwoBg : #f8bebd;
$toggle_txt_color : #333333;

// MultiSelect Select Box

$txt_color : #404040;
$bg_select_hover : #dee2e6;
$bg_select : #ffffff;
$bg_counter : lightblue;
$disabled_bg : lightgray;
$border-color : #aaaaaa;


// Global Txt Color 

$globalTxt_color : #333333;


// Main Tabbar Style
$tab_disabled : #b0b0b0;
$tab_pending : #e9bd2c;
$tab_ongoing : #e4e3e7;
$tab_success : #4aaf51;
$tab_txtColor : #fff;
$tab_BgColor_active : #404040;
$tab_BorderColor : #dee2e6;
$tab_ShadowColor : #cccccc;
$tab_BgColor : transparent;
$tab_hoverColor : #fbfbfb;

// Nested Tabbar Style
$tab_NBgColor_active : #404040;
$tab_NBgColor : #fff;
$n_tabTxt_active : #ff8500;
$n_tabTxt : #404040;
$n_tab_border : #cccccc;


$link_color : #2353ce;

// Toast Message color codes

$toast_danger : #d32f2f;
$toast_success : #2e7d32;
$toast_warning : #ed6c02;
$toast_info : #0288d1;
$toast_shadowOne : #00000033;
$toast_shadowTwo : #00000024;
$toast_shadowThree : #0000001f;

// accordion color codes
$accordion_header_button: #f7f7f7;
$accordion_header_button_expanded: #eeeeee;

// CSV download button Style

$button_Csv : #705a42;
// Common colors

$white_1: #ffffff;
$white_2: #f4f6f9;
$white_3: #f1f1f1;
$white_6: #e1e1e1;
$white_4: #f5f5f5;
$white_5: #e5e5e5;
$white_6: #fcf8e3;
$smokeWhite : #cccccc;
$smokeWhite_2 : #f5f6f8;
$smokeWhite_3 : #dee2e6;
$smokeWhite_4 : #f7f6fb;
$smokeGray: #4b545c;
$smokeGray_2: #a9a9a9;
$smokeGray_4: #404040;
$smokeGray_5: #212529;
$smokeGray_6: #5F5F5F;
$smokeGray_7: #393a3c;
$smokeGray_8: #4a4a4a;
$smokeGray_9: #495057;
$smokeGray_10: #767676;
$smokeGray_11: #333333;
$smokeGray_12: #444444;
$smokeGray_13: #e0e1e1;
$balck : #000000;
$green_1 : #c4ddbf;
$green_2 : #549446;
$green_tick : #4aaf51;
$yellow_tick: #e9bd2b;
$Light_red_1: #f8bebd;
$Light_red_2: #dd4b39;
$red_2 : #dc3546;
$btn_orrange: #ff8636;
$orange_2 : #ff8500;
$orange_4 : #ff7d00;
$orange_hover: #fff9f3;
$l_brown : #8a6d3b;
$l_blue : #2353ce;
$error_red: #ff3333;
$info-gray: #a9a9a9;






/*-----Font family Vairable-----*/

$primary-font: 'Roboto',
sans-serif;
$primary-font-size: 87.5%;



/*------BreakPoints---------*/

$break-xs: 567px;
$break-sm: 767px;
$break-md: 991px;
$break-lg: 1024px