@import "src/assest/sass/_variables.scss";

#orderSummaryMain {
  .filtersControlsHeader {
    padding: 5px 20px 15px 20px;
    display: grid;
    column-gap: 10px;
    align-items: flex-end;
    background-color: $smokeWhite_2;
  }

  .marginBottom {
    margin-bottom: 1px;
  }

  .btnContainer {
    display: flex;
    align-items: baseline;
    justify-content: flex-end;
    gap: 10px;
    flex-wrap: wrap;
  }
}
