@import "src/assest/sass/_variables.scss";

.confirmationPopupMain {
  display: grid;
  grid-template-rows: min-content auto;
  border: none;
  overflow: hidden;

  .dialogHeader {
    background-color: $infoBox_bg;
    color: $header_color;
    border-radius: 0.5rem;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    display: grid;
    place-content: center;
    row-gap: 15px;
    padding: 10px 0;
    position: relative;
  }

  .questionIcon {
    width: 45px;
    height: 45px;
    margin: 0 auto;
    color: $header_color;
  }

  .confirmationHeading {
    text-transform: uppercase;
    font-weight: 700;
    text-align: center;
    font-size: 20px;
  }

  .closeControl {
    position: absolute;
    top: 13px;
    right: 13px;
    background-color: transparent;
    outline: none;
    border: none;
    font-size: 20px;
    color: $infoBox_bg_txt;
  }

  .hideCloseControl {
    position: absolute;
    top: 13px;
    right: 6px;
    background-color: transparent;
    outline: none;
    border: none;
    font-size: 20px;
    color: $infoBox_bg_txt;
  }

  .headerContainer {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  .dialogContent {
    padding: 20px 40px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    max-height: 500px;

    .confirmationMessage {
      font-size: 15px;
      color: $infoBox_bg_txt;
      text-align: center;
    }

    .boldConfirmationMessage {
      font-weight: bold;
      font-size: 15px;
      color: $infoBox_bg_txt;
      text-align: center;
      height: auto;
      overflow: auto;
    }

    .controlsSection {
      display: grid;
      grid-template-columns: 100px 100px;
      column-gap: 20px;
      justify-content: center;
    }
  }
}
