@import "src/assest/sass/_variables.scss";

.searchFieldMain {
  display: grid;
  grid-template-columns: auto 40px;
  align-items: center;
  border: 1px solid $smokeGray_2;
  border-radius: 5px;
  position: relative;

  &:hover {
    .clearFieldControl {
      display: block;
    }
  }

  .searchInput {
    padding: 8px 5px;
    border: none;
    outline: none;
    border-radius: 5px;

    &:focus {
      border: none;
    }
  }

  .searchButtonControl {
    width: 40px;
    height: 100%;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    border: none;
    outline: none;
    background-color: $header_color;
    color: $white_1;
  }

  .clearFieldControl {
    position: absolute;
    right: 45px;
    font-size: 20px;
    border: none;
    outline: none;
    background-color: inherit;
    color: $header_color;
    display: none;
    width: 30px;
    height: 30px;
    border-radius: 30px;

    &:hover {
      background-color: $smokeGray_13;
    }
  }
}
