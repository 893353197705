@import "src/assest/sass/_variables.scss";

#gapsInCredMain {
  padding: 15px;

  .employeeNameLabelSection {
    display: grid;
    grid-template-columns: 30px auto;
    align-items: center;
    column-gap: 10px;

    .employeeIconContainer {
      width: 30px;
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: $orng_btn_bg;
      border-radius: 15px;
      color: $white_1;
    }

    .employeeName {
      font-size: 17px;
      letter-spacing: 0.2px;
      color: $smokeGray_10;
    }
  }

  .gapsInCredListSection {
    margin: 20px 0;
    display: grid;
    grid-auto-rows: max-content;
    row-gap: 20px;
  }

  .gapsInCredAccordionHeader {
    .checkbox {
      margin-right: 5px;
    }
    .asteriskIcon {
      color: $error_red;
      font-size: 8px;
      margin-top: -7px;
      margin-right: 3px;
    }

    .activityName {
      font-size: 14px;
      cursor: pointer;
    }

    .docCountLabel {
      font-size: 12px;
      color: $green_tick;
      margin-left: 10px;
      margin-top: 2px;
      cursor: pointer;

      &.zerDocument {
        color: $toast_warning;
      }

      &.expiredCountLabel {
        color: $toast_danger;
      }

      &.notApplicableCountLabel {
        color: black;
      }
    }
  }
}

.gapsInCredDocumentItemMain {
  padding: 10px 15px;
  border-bottom: 1px solid $smokeWhite;
  transition: box-shadow 0.2s ease-in;
  display: flex;
  justify-content: space-between;

  &:hover {
    box-shadow: 0 3px 10px $shadowOne;
  }

  &:last-child {
    border: none;
  }

  .documentItemLabels {
    display: flex;
    gap: 10px;
    align-items: center;
  }

  .documentType {
    font-size: 13.5px;
  }

  .expiryDate {
    padding: 2px 10px;
    font-size: 12px;
    border-radius: 3px;
    border: 1px solid $smokeWhite;
    background-color: $smokeWhite_2;
    color: $smokeGray_2;
    min-width: 160px;

    &.expired {
      border: 1px solid $toggle_buttonTwo;
      background-color: $toggle_buttonTwoBg;
      color: $toggle_buttonTwo;
    }
  }

  .notApplicableLable {
    padding: 2px 10px;
    font-size: 12px;
    border-radius: 3px;
    border: 1px solid $n_tabTxt_active;
    background-color: #fffad2;
    color: $notification_txt;
    min-width: 160px;
  }

  .documentItemControls {
    display: flex;
    gap: 10px;

    .documentControl {
      background-color: $orange_2;
      color: #fff;
      padding: 0;
      border: none;
      font-size: 12px;
      width: 30px;
      height: 30px;
      border-radius: 30px;
      box-shadow: 0 3px 10px $shadowOne;
    }

    .disabledDocumentControl {
      background-color: grey;
    }
  }
}
