@import "src/assest/sass/_variables.scss";

@mixin yesNoLabel {
  padding: 2px 10px;
  font-size: 12px;
  border-radius: 3px;
  border: 1px solid $smokeWhite;
}

.paginatedTableMain {
  box-shadow: 1px 1px 3px $smokeWhite;
  border-radius: 5px;
  overflow: auto;

  .paginatedTable {
    width: 100%;
    border-collapse: collapse;
    border-bottom: 1px solid $smokeGray_2;

    th {
      white-space: nowrap;
      text-transform: uppercase;
    }

    td {
      border: 1px solid #e0dfdf;
    }

    td,
    th {
      padding: 8px;
    }

    .longText {
      max-width: 150px;
      white-space: normal;
      word-wrap: break-word;
    }

    .boldCss {
      font-weight: bold;
    }

    tr:nth-child(even) {
      background-color: $table_odd_tr_bg;
    }

    tr:hover {
      background-color: $table_hover_color;
    }

    th {
      padding-top: 12px;
      padding-bottom: 12px;
      text-align: left;
      background-color: $header_color;
      color: $white_1;
      letter-spacing: 0.3px;
      font-size: 13.3px;
    }

    .yesNoLabelCell {
      text-align: center;
    }

    .yesLabel {
      @include yesNoLabel;
      color: $white_1;
      background-color: $green_tick;
    }

    .noLabel {
      @include yesNoLabel;
      background-color: $smokeWhite_2;
      color: $smokeGray_2;
    }

    .multipleValuesCell {
      display: grid;
      grid-auto-rows: max-content;
      row-gap: 3px;

      .multipleValueLabel {
        @include yesNoLabel;
        background-color: $smokeWhite_2;
        color: $header_color;
        min-height: 24px;
        text-align: center;
        min-width: 115px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }
  }
}

.paginatedTableFooter {
  padding: 15px 8px;
  display: flex;
  justify-content: space-between;

  .pageLimitControl {
    display: grid;
    grid-template-columns: max-content 51px;
    column-gap: 10px;
    align-items: center;

    label {
      font-size: 14px;
      color: $smokeGray_10;
    }

    .pageLimitControl {
      border: none;
      padding: 3px;
    }
  }

  .paginationControls {
    display: grid;
    grid-auto-columns: max-content;
    grid-auto-flow: column;
    column-gap: 10px;

    .pageNavigationControl {
      border: 1px solid $smokeGray_10;
      border-radius: 30px;
      min-width: 30px;
      height: 30px;
      background-color: inherit;

      &.disabled,
      &[disabled] {
        opacity: 0.5;
        cursor: not-allowed;
      }

      &.active {
        background-color: $header_color;
        color: $white_1;
      }
    }

    .ellipsis {
      font-size: 20px;
      color: $smokeGray_10;
      margin-top: 10px;
    }
  }
}

@media (max-width: $break-sm) {
  .paginatedTableMain {
    overflow: auto;
  }

  .paginatedTableFooter {
    gap: 30px;
  }
}
