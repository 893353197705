@import "src/assest/sass/_variables.scss";

.userProfileAvatar {
  background-color: $orng_btn_bg;
  width: 3rem;
  height: 3rem;
  margin-right: 0.5rem;
  border-radius: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  color: $white_1;
}

.profileImageContainer {
  height: 3rem;
  width: 3rem;
  margin-right: 0.5rem;

  .profileImage {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
}
