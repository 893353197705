@import "src/assest/sass/_variables.scss";

#errorDialogMain {
  display: grid;
  grid-template-rows: min-content auto;
  border: none;
  overflow: hidden;

  .dialogHeader {
    background-color: $toggle_buttonTwoBg;
    color: $toggle_buttonTwo;
    border-radius: 0.5rem;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    display: grid;
    place-content: center;
    row-gap: 15px;
    padding: 10px 0;
    position: relative;
  }

  .errorHeading {
    text-transform: uppercase;
    font-weight: 700;
    text-align: center;
    font-size: 20px;
  }

  .errorIcon {
    width: 45px;
    height: 45px;
    margin: 0 auto;
  }

  .dialogContent {
    padding: 20px 40px;
    display: grid;
    grid-template-rows: max-content 36px;
    row-gap: 10px;

    .errorMessage {
      font-size: 15px;
      color: $infoBox_bg_txt;
      text-align: center;
      letter-spacing: 0.3px;
    }

    .goBackControl {
      width: 250px;
      margin: 0 auto;
    }

  }

}