@font-face {
  font-family: 'icomoon';
  src: url('/src/assest/fonts/icomoon.eot?s9pvs6');
  src: url('/src/assest/fonts/icomoon.eot?s9pvs6#iefix') format('embedded-opentype'), url('/src/assest/fonts/icomoon.ttf?s9pvs6') format('truetype'), url('/src/assest/fonts/icomoon.woff?s9pvs6') format('woff'), url('/src/assest/fonts/icomoon.svg?s9pvs6#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-info:before {
  content: "\e900";
}

.icon-admin:before {
  content: "\e901";
}

.icon-cred:before {
  content: "\e902";
}

.icon-cyber:before {
  content: "\e903";
}

.icon-dasboard:before {
  content: "\e904";
}

.icon-hr:before {
  content: "\e905";
}

.icon-lib:before {
  content: "\e906";
}

.icon-Organizational:before {
  content: "\e907";
}

.icon-policy:before {
  content: "\e908";
}

.icon-reports:before {
  content: "\e909";
}

.icon-risk:before {
  content: "\e90a";
}

.icon-superadmin:before {
  content: "\e90b";
}

.icon-users:before {
  content: "\e90c";
}

.icon-vendors:before {
  content: "\e90d";
}

.icon-download:before {
  content: "\e90e";
}

.icon-empty:before {
  content: "\e90f";
}

.icon-ongoing:before {
  content: "\e910";
}

.icon-processdone:before {
  content: "\e911";
}

.icon-processpending:before {
  content: "\e912";
}