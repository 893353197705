@import "src/assest/sass/_variables.scss";

.documentsCredentialingEditorMain {
  position: relative;

  .instructionsControl {
    position: absolute;
    top: 13px;
    right: 15px;
    width: 33.5px;
    height: 33.5px;
    border: none;
    background-color: inherit;

    .instructionIcon {
      width: 25px;
      height: 25px;
      margin: 0 auto;

      path {
        fill: $header_color;
      }
    }
  }

}